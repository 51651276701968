import { AppState } from '../index';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { SubsidiaryState } from './subsidiary.state';
import { Subsidiary } from '@shared/models/subsidiary.model';

export const selectSubsidiarysFeature: MemoizedSelector<AppState, SubsidiaryState> =
  createFeatureSelector<SubsidiaryState>('Subsidiary');

export const selectSubsidiarys: MemoizedSelector<AppState, Subsidiary[]> =
createSelector(
  selectSubsidiarysFeature,
  ({ entities }: SubsidiaryState): Subsidiary[] =>
    Object.values(entities) as Subsidiary[]
);
