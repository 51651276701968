import { createAction, props } from '@ngrx/store';
import { Subsidiary } from '@shared/models/subsidiary.model';

export const subsidiaryKey = '[subsidiary]';

export const addSubsidiary = createAction(
  `${subsidiaryKey} Add Subsidiary`,
  props<{ subsidiary: Subsidiary }>()
);

export const deleteSubsidiary = createAction(
  `${subsidiaryKey} Delete Subsidiary`,
  props<{ id: number }>()
);

export const deletesubsidiaryuccess = createAction(
`${subsidiaryKey} Delete Subsidiary Success`
);

export const deleteSubsidiaryError = createAction(
`${subsidiaryKey} Delete Subsidiary Error`
);
