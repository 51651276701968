import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { MtxButtonModule } from '@ng-matero/extensions/button';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { MatOption, MatOptionModule } from '@angular/material/core';

import { AuthService } from '@core/authentication';
import { LocalStorageService } from '@shared';
import { SubsidiaryService } from '@shared/services/subsidiary.service';
import { Subsidiary } from '@shared/models/subsidiary.model';
import { Store } from '@ngrx/store';
import { addSubsidiary } from 'app/store/subsidiary';

@Component({
  selector: 'app-select-subsidiary',
  templateUrl: './select-subsidiary.component.html',
  styleUrls: ['./select-subsidiary.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MtxButtonModule,
    TranslateModule,
    MatOptionModule,
  ],
})
export class SelectSubsidiaryComponent implements OnInit  {
  isSubmitting = false;
  data: Subsidiary[] = [];
  form = this.fb.nonNullable.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
    rememberMe: [false],
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: SubsidiaryService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.service.getAll().subscribe(
      (response) => {
        this.data = response;
      },
      (error: HttpErrorResponse) => {
        console.error(error);
      }
    );
  }

  submit(): void {

  }

  next(): void {
    console.log(this.data[0], 'foi');
    this.store.dispatch(addSubsidiary({ subsidiary: this.data[0] }));
    this.router.navigateByUrl('/');
  }

}
