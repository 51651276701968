import { Injectable, inject } from '@angular/core';
import { AuthService } from '@core/authentication';
import { User } from '@core/authentication/interface/user.interface';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { switchMap, tap } from 'rxjs/operators';
import { Menu, MenuService } from './menu.service';
import { CompanyService } from '@shared/services/company.service';
import { getCompany$ } from 'app/store/companys/companys.effects';
import { CompanysFacade } from 'app/store/companys';
import { Company } from '@shared/models/company.model';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(
    private authService: AuthService,
    private menuService: MenuService,
    private companyService: CompanyService,
    private permissonsService: NgxPermissionsService,
    private rolesService: NgxRolesService
  ) {}

  private readonly companyFacade: CompanysFacade = inject(CompanysFacade);
  /**
   * Load the application only after get the menu or other essential informations
   * such as permissions and roles.
   */
  load() {
   return new Promise<void>((resolve, reject) => {
      this.authService
        .change()
        .pipe(
          tap(user => this.setPermissions(user)),
          tap(user => this.loadCompany(user)),
          switchMap(() => this.authService.menu()),
          tap(menu => this.setMenu(menu))
        )
        .subscribe({
          next: () => resolve(),
          error: () => resolve(),
        });
    });
  }

  private setMenu(menu: Menu[]) {
    this.menuService.addNamespace(menu, 'menu');
    this.menuService.set(menu);
  }

  private setPermissions(user: User) {
    // In a real app, you should get permissions and roles from the user information.
   const permissions = ['canAdd', 'canDelete', 'canEdit', 'canRead'];
   const dsds = { ADMIN: permissions };
   const dsdsdsd = user.roles;

    this.permissonsService.loadPermissions(permissions);
    this.rolesService.flushRoles();
    this.rolesService.addRoles({ ADMIN: permissions });

    // Tips: Alternatively you can add permissions with role at the same time.
     //this.rolesService.addRolesWithPermissions({ ADMIN: permissions });
     //this.rolesService.flushRoles();
  }

  car = {} as Company;

  private loadCompany(user: User) {
    console.log('loadCompany', user);

    this.companyService.get(user.empresa_id!).subscribe((company: Company) => {
      this.companyFacade.addCompany(company);
      this.car = company;
    });

    console.log('loadCompany2', this.car);

  }
}
