import { ActionReducer, createReducer, on } from '@ngrx/store';
import { adapter, initialState, SubsidiaryState } from './subsidiary.state';
import { addSubsidiary, deleteSubsidiary } from './subsidiary.actions';

export const SubsidiaryReducers: ActionReducer<SubsidiaryState> = createReducer(
  initialState,
  on(addSubsidiary, (state: SubsidiaryState, { subsidiary }) =>
    adapter.addOne(subsidiary, state)),
  on(deleteSubsidiary, (state: SubsidiaryState, { id }) =>
    adapter.removeOne(id, state))
);
