import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { deleteSubsidiary, deleteSubsidiaryError, deletesubsidiaryuccess } from './subsidiary.actions';
//import { UsersApiService } from '../../shared/services/messages-api.service';
import { catchError, map, mergeMap } from 'rxjs';

@Injectable()
export class SubsidiaryEffects {

}
/*
export const deleteUser$ = createEffect(
  (actions$: Actions = inject(Actions), messagesApiService: UsersApiService = inject(UsersApiService)) => {
    return actions$.pipe(
      ofType(deleteUser),
      mergeMap(({ id }) =>
        messagesApiService.deleteUser(id).pipe(
          map(() => deleteUserSuccess()),
          catchError(() => [deleteUserError()])
        )
      )
    );
  },
  { functional: true }
);
*/
