import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Subsidiary } from '@shared/models/subsidiary.model';

import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class SubsidiaryService extends BaseService<Subsidiary> {
  constructor(protected http: HttpClient) {
    super(http, 'filial');
  }
}
