<div class="d-flex w-full h-full">
  <mat-card class="m-auto" style="max-width: 380px;">
    <mat-card-header class="m-b-24">
      <mat-card-title>{{'login_title' | translate}}!</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form class="form-field-full" [formGroup]="loginForm">
        <mat-form-field appearance="outline">
          <mat-label>{{'email' | translate}}</mat-label>
          <input matInput formControlName="username" required>
          @if (username.invalid) {
            <mat-error>
              @if (username.errors?.required) {
                <span>{{'please_enter' | translate}}
                  <strong>{{'email' | translate}}</strong>
                </span>
              }
              @if (username.errors?.remote) {
                <span>{{ username.errors?.remote }}</span>
              }
            </mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{'password' | translate}}</mat-label>
          <input matInput type="password" placeholder="********"
            formControlName="password" required>
          @if (password.invalid) {
            <mat-error>
              @if (password.errors?.required) {
                <span>{{'please_enter' | translate}}
                  <strong>{{'password' | translate}}</strong>
                </span>
              }
              @if (password.errors?.remote) {
                <span>{{ password.errors?.remote }}</span>
              }
            </mat-error>
          }
        </mat-form-field>

        <mat-checkbox formControlName="rememberMe">{{'remember_me' | translate}}
        </mat-checkbox>

        <button id="login_button" class="w-full m-y-16" mat-raised-button color="primary"
          [disabled]="!!loginForm.invalid" [loading]="isSubmitting"
        (click)="login()">{{'login' | translate}}</button>

        <div>{{'have_no_account' | translate}}?
          <a routerLink="/auth/register">{{'create_one_account' | translate}}</a>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
