import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@core/authentication';
import { User } from '@core/authentication/interface/user.interface';
import { TranslateModule } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { Observable, startWith } from 'rxjs';
import { Company } from '@shared/models/company.model';
import { CommonModule } from '@angular/common';
import { CompanysFacade } from 'app/store/companys';

@Component({
  selector: 'app-user-panel',
  template: `
    <div class="matero-user-panel">
      <img class="matero-user-panel-avatar" [src]="user.imagem" alt="avatar" width="64" />
      <h4 class="matero-user-panel-name">{{ getCompanyName() }}</h4>
      <h5 class="matero-user-panel-email">{{ user.name }} - {{ user.email }}</h5>
      <div class="matero-user-panel-icons">
        <button
          mat-icon-button
          routerLink="/profile/overview"
          matTooltip="{{ 'profile' | translate }}"
        >
          <mat-icon>account_circle</mat-icon>
        </button>
        <button
          mat-icon-button
          routerLink="/auth/select-subsidiary"
          matTooltip="{{ 'change_subsidiary' | translate }}"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="logout()" matTooltip="{{ 'logout' | translate }}">
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./user-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    RouterLink,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    CommonModule,
  ],
})
export class UserPanelComponent implements OnInit {
  user!: User;
  company: Company | undefined;

  private readonly companyFacade: CompanysFacade = inject(CompanysFacade);

  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.auth.user().subscribe(user => (this.user = user));
    this.companyFacade.companys$.subscribe(x => (this.company = x[0]));
  }

  logout() {
    this.auth.logout().subscribe(() => {
      this.router.navigateByUrl('/auth/login');
    });
  }

  getCompanyName() {
    return this.company?.nome_fantasia;
  }
}
