import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, iif, merge, of } from 'rxjs';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';
import { filterObject, isEmptyObject } from './helpers';
import { User } from './interface/user.interface';
import { LoginService } from './login.service';
import { TokenService } from './token.service';
import { UsersFacade } from 'app/store/users';
import { MessagesFacade } from 'app/store/messages';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user$ = new BehaviorSubject<User>({});
  private readonly messagesFacade: MessagesFacade = inject(MessagesFacade);
  private readonly userFacade: UsersFacade = inject(UsersFacade);

  private change$ = merge(
    this.tokenService.change(),
    this.tokenService.refresh().pipe(switchMap(() => this.refresh()))
  ).pipe(
    switchMap(() => this.assignUser()),
    share()
  );

  constructor(
    private loginService: LoginService,
    private tokenService: TokenService
  ) {}

  init() {
    return new Promise<void>(resolve => this.change$.subscribe(() => resolve()));
  }

  change() {
    return this.change$;
  }

  check() {
    return this.tokenService.valid();
  }

  login(username: string, password: string, rememberMe = false) {
    return this.loginService.login(username, password, rememberMe)
    .pipe(
      tap((user) => {
        this.tokenService.set({ access_token: user.token, token_type: 'bearer' });
        console.log('dsdsds', user);
      } ),
      map(() => this.check()),
    );
  }

  refresh() {
    return this.loginService
      .refresh(filterObject({ refresh_token: this.tokenService.getRefreshToken() }))
      .pipe(
        catchError(() => of(undefined)),
        tap(token => this.tokenService.set(token)),
        map(() => this.check())
      );
  }

  logout() {
    this.userFacade.deleteUser(this.user$.getValue().id!);
    return this.loginService.logout().pipe(
      tap(() => this.tokenService.clear()),
      map(() => !this.check())
    );
  }

  user() {
    return this.user$.pipe(share());
  }

  menu() {
    //return  this.loginService.menu();
    return iif(
      () => this.check(), this.loginService.menu(), of([])
    );
  }

  private assignUser() {
    if (!this.check()) {
      return of({}).pipe(tap(user => this.user$.next(user)));
    }

    if (!isEmptyObject(this.user$.getValue())) {
      return of(this.user$.getValue());
    }

    return this.loginService
      .details()
      .pipe(
        tap(user =>  {
          this.user$.next(user);
          user.notify?.forEach(element => {
            //this.messagesFacade.addMessage(element)
          });
        })
    );
  }
}
