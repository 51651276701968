import { ChangeDetectorRef, Component, inject, OnInit, OnDestroy } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { Notification } from '@shared/models/notification.model';
import { MessagesFacade } from 'app/store/messages';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-notification',
  template: `
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon matBadge="{{ getTotalNotify() }}" matBadgeColor="warn" aria-hidden="false">notifications</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <mat-nav-list>
        @for (message of messages; track trackByFn) {
          <mat-list-item (click)="markAsRead(message)">
            <mat-icon class="m-x-16" matListItemIcon>{{ message.type }}</mat-icon>
            <a matListItemTitle href="{{ message.url }}" target="_blank">{{ message.message }}</a>
          </mat-list-item>
        }
        @empty {
          <mat-list-item>Nenhuma notificação.</mat-list-item>
        }
      </mat-nav-list>
    </mat-menu>
  `,
  standalone: true,
  imports: [MatBadgeModule, MatButtonModule, MatIconModule, MatListModule, MatMenuModule],
})
export class NotificationComponent implements OnInit, OnDestroy {
  private readonly messagesFacade: MessagesFacade = inject(MessagesFacade);
  messages: Notification[] = [];
  messagesSubscription!: Subscription;

  constructor(private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.messagesFacade.loadMessages();
    this.messagesSubscription = this.messagesFacade
      .messages$
      .subscribe(
        messages => this.messages = messages
      );
  }

  ngOnDestroy() {
    this.messagesSubscription.unsubscribe();
  }

  getTotalNotify() {
    return this.messages.filter(x => x.read == false).length;
  }

  markAsRead(message: Notification) {
    if (message.read) {
      return;
    }
    this.messagesFacade.markReadNotify(message);
  }

  trackByFn(index: number, item: Notification) {
    return item.id;
  }

}


