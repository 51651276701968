<div class="d-flex w-full h-full">
  <mat-card class="custom-card">
    <mat-card-header class="m-b-24">
      <mat-card-title>Escolha a filial de trabalho</mat-card-title>
    </mat-card-header>

    <form id="empresaForm" action="proxima_pagina.html" method="POST">
      <mat-form-field appearance="outline">
        <mat-label>Empresa</mat-label>
        <mat-select formControlName="tipo" required>
          @for (item of data; track item) {
            <mat-option [value]="item.id">{{ item.cnpj }} - {{ item.razao_social }}</mat-option>
          }
          @if (form.get('tipo')?.invalid) {
            <mat-error>Escolha o tipo de pessoa</mat-error>
          }
        </mat-select>
      </mat-form-field>
      <button id="select_company_button" class="w-full m-y-10" mat-flat-button color="primary" [disabled]="!!form.invalid" (click)="next()">
        Avançar
      </button>
    </form>
  </mat-card>
</div>
