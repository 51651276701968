import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseService<T> {
  protected url: string;

  constructor(protected http: HttpClient, @Inject('API_ENDPOINT') endpoint: string) {
    this.url = `${environment.APIbaseUrl}/${endpoint}`;
  }

  get(id: number): Observable<T> {
    return this.http.get<T>(`${this.url}/${id}`);
  }

  getAll(search?: string): Observable<T[]> {
    const params = search ? `?search=${search}` : '';
    return this.http.get<T[]>(`${this.url}${params}`);
  }

  post(entity: T): Observable<T> {
    return this.http.post<T>(`${this.url}`, entity);
  }

  put(id: number, entity: T): Observable<any> {
    return this.http.put<any>(`${this.url}/${id}`, entity);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }
}
